import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Faq from "react-faq-component";
import faq from "./data";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  border: none;
  background: url('/config/images/CONNECT-WALLET-300x140.png');
  color: var(--secondary-text);
  width: 300px;
  height: 140px;
  display: flex;
  align-items: center;
  cursor: pointer;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  padding-top: 20px;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

const styles = {
  bgColor: "#021838",
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: 'white',
  rowContentTextSize: "16px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingLeft: "0px",
  arrowColor: "white",
};

const config = {
  animate: true,
};

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <div className="bg-container">
        <header>
          <div className="main-menu">
            <div className="left-menu">
              <img
                className="main-icon"
                src="/config/images/bogus-logo-favi.png"
              ></img>
              <a className="main-menu-item" href="#about">
                About
              </a>
              <a className="main-menu-item" href="#rarity">
                Rarity
              </a>
              <a className="main-menu-item" href="#roadmap">
                Road Map
              </a>
              <a className="main-menu-item" href="#team">
                Team
              </a>
              <a className="main-menu-item" href="#faq">
                FAQ
              </a>
            </div>
            <div className="right-menu">
              <a target="_blank" href="https://www.instagram.com/bogus_fish/">
                <img
                  className="menu-icon"
                  src="/config/images/instagramlogo.svg"
                ></img>
              </a>
              <a target="_blank" href="https://discord.gg/ksKaKPMXWg">
                <img
                  className="menu-icon"
                  src="/config/images/discordlogo.svg"
                ></img>
              </a>
              <a target="_blank" href="https://twitter.com/bogusfish">
                <img
                  className="menu-icon"
                  src="/config/images/twitterlogo.svg"
                ></img>
              </a>
              <img
                className="menu-icon hide"
                src="/config/images/opensealogo.svg"
              ></img>
            </div>
          </div>
        </header>
        <div className="row">
          <div className="single-col">
            <p className="start-text dark">Welcome to</p>
            <p className="start-slogan dark">Bogus Fish</p>
            <img
              className="main-hero"
              src="/config/images/bogus-animated-500x380-C.gif"
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="single-col">
            <p className="big-text">
              Follow our Communites for more info on when Bogus Fish NFTs will
              be available.
            </p>
            <a target="_blank" href="https://www.instagram.com/bogus_fish/">
              <img
                className="temp-icon"
                src="/config/images/instagramlogo.svg"
              ></img>
            </a>
            <a target="_blank" href="https://discord.gg/ksKaKPMXWg">
              <a target="_blank" href="https://twitter.com/bogusfish">
                <img
                  className="temp-icon"
                  src="/config/images/twitterlogo.svg"
                ></img>
              </a>
              <img
                className="temp-icon"
                src="/config/images/discordlogo.svg"
              ></img>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="single-col" id="about">
            <p className="med-text">Bogus Fish</p>
            <p className="big-text">
              Bogus Fish is a collection of 2,000 handfish. Stay tuned for more
              Bogus fish in boxes. The first Bogus designs were created over 30
              years ago and were part of beach culture active wear seen up and
              down the coast of California.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="gallery">
            <img className="one-fourth" src="/config/images/b1.jpg"></img>
            <img className="one-fourth" src="/config/images/b2.jpg"></img>
            <img className="one-fourth" src="/config/images/b3.jpg"></img>
            <img className="one-fourth" src="/config/images/b4.jpg"></img>
            <img className="one-fourth" src="/config/images/b5.jpg"></img>
            <img className="one-fourth" src="/config/images/b6.jpg"></img>
            <img className="one-fourth" src="/config/images/b7.jpg"></img>
            <img className="one-fourth" src="/config/images/b8.jpg"></img>
            <img className="one-fourth" src="/config/images/b9.jpg"></img>
            <img
              className="one-fourth hidden"
              src="/config/images/b10.jpg"
            ></img>
            <img
              className="one-fourth hidden"
              src="/config/images/b11.jpg"
            ></img>
            <img
              className="one-fourth hidden"
              src="/config/images/b12.jpg"
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="single-col" id="rarity">
            <p className="med-text">Rarity Fish</p>
          </div>
        </div>
        <div className="row">
          <div className="rare">
            <div className="rarity-con">
              <img
                className="rarity-img"
                src="/config/images/bogus-rarity-400-1.png"
              ></img>
              <p className="rare-text s-bigger">Silver</p>
              <p className="rare-text s-bigger">15% Drop</p>
            </div>
            <div className="rarity-con">
              <img
                className="rarity-img"
                src="config/images/bogus-rarity-400-2.png"
              ></img>
              <p className="rare-text s-bigger">Gold</p>
              <p className="rare-text s-bigger">5% Drop</p>
            </div>
            <div className="rarity-con">
              <img
                className="rarity-img"
                src="/config/images/bogus-rarity-400-3.png"
              ></img>
              <p className="rare-text s-bigger">Sparkle</p>
              <p className="rare-text s-bigger">1% Drop</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="single-col" id="roadmap">
            <p className="med-text">Road Map</p>
          </div>
        </div>
        <div className="row">
          <div className="map-container">
            <img
              className="map-size"
              src="/config/images/roadmap-bogus-500x1200-1.png"
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="single-col" id="team">
            <p className="med-text">Development Team</p>
            <p className="big-text">
              The project is led by Ron Hoopes who is the creative director and
              lead artist on the project. Ron is surrounded by an awesome team
              of NFT developers and technology experts.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="double-col">
              <div className="team">
                <img
                  className="img-size"
                  src="/config/images/schooloffish-200x140-1.png"
                ></img>
                <p className="name">Ron</p>
                <p className="role">Artist / Creative Director</p>
              </div>
              <div className="team">
                <img
                  className="img-size"
                  src="/config/images/schooloffish-200x140-3.png"
                ></img>
                <p className="name">Slynas</p>
                <p className="role">Lead Developer</p>
              </div>
              <div className="team">
                <img
                  className="img-size"
                  src="/config/images/schooloffish-200x140-5.png"
                ></img>
                <p className="name">Nick</p>
                <p className="role">Community Manager</p>
              </div>
            </div>
            <div className="double-col">
              <div className="team">
                <img
                  className="img-size"
                  src="/config/images/schooloffish-200x140-2.png"
                ></img>
                <p className="name">SkyBlazer</p>
                <p className="role">Artist, Community Manager</p>
              </div>
              <div className="team">
                <img
                  className="img-size"
                  src="/config/images/schooloffish-200x140-4.png"
                ></img>
                <p className="name">OceanBreezy</p>
                <p className="role">Moderator</p>
              </div>
              <div className="team">
                <img
                  className="img-size"
                  src="/config/images/schooloffish-200x140-6.png"
                ></img>
                <p className="name">Erica</p>
                <p className="role">Office Manager</p>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-row" id="faq">
          <Faq
            className="single-col"
            data={faq}
            styles={styles}
            config={config}
          />
        </div>
        <footer>
          <div className="f-top">
            <img
              className="bottom-icon"
              src="/config/images/bogus-logo-favi.png"
            ></img>
          </div>
          <div className="f-bottom">
            <a target="_blank" href="https://www.instagram.com/bogus_fish/">
              <img
                className="b-menu-icon"
                src="/config/images/instagramlogo.svg"
              ></img>
            </a>
            <a target="_blank" href="https://discord.gg/ksKaKPMXWg">
              <img
                className="b-menu-icon"
                src="/config/images/discordlogo.svg"
              ></img>
            </a>
            <a target="_blank" href="https://twitter.com/bogusfish">
              <img
                className="b-menu-icon"
                src="/config/images/twitterlogo.svg"
              ></img>
            </a>
            <img
              className="b-menu-icon hide"
              src="/config/images/opensealogo.svg"
            ></img>
          </div>
        </footer>
      </div>
    </s.Screen>
  );
}

export default App;
