const faq = {
  title: <p className="med-text">FAQ</p>,
  rows: [
    {
      title: <p className="question">What is a Bogus Fish?</p>,
      content: (
        <p className="answer">
          Bogus Fish is a collection of 2,000 unique pieces of artwork. The
          project is led by Ron Hoopes who is the creative director and lead
          artist on the project. Ron is surrounded by an awesome team of NFT
          developers and technology experts. The first Bogus designs were
          created over 30 years ago and were part of beach culture active wear
          seen up and down the coast of California.
        </p>
      ),
    },
    {
      title: (
        <p className="question">
          What is the estimated fish hatchery release (mint) date?
        </p>
      ),
      content: (
        <p className="answer">
          Stay tuned for news on the release (mint) date. On that date, we will
          release 2,000 Bogus Fish from the hatchery. The first release from the
          hatchery will feature the handfish. Be on the lookout for a second
          release date that will feature the round fish.
        </p>
      ),
    },
    {
      title: <p className="question">Estimated dates (subject to change):</p>,
      content: (
        <p className="answer">
          September 2022, will likely be the month to see the Pre-sale and the
          Public sale mint of the handfish. Check back for updates to be
          announced as the date gets closer.
        </p>
      ),
    },
    {
      title: (
        <p className="question">How much will it cost to mint a Bogus Fish?</p>
      ),
      content: (
        <p className="answer">
          Mint and gas prices are changing month to month with new innovations
          and current trends. Stay tuned for pricing once established.
        </p>
      ),
    },
    {
      title: <p className="question">Will there be a white list?</p>,
      content: (
        <p className="answer">
          Yes. Please check Twitter @bogusfish and Instagram @bogus_fish for
          methods.
        </p>
      ),
    },
    {
      title: <p className="question">How many Bogus Fish can I mint?</p>,
      content: (
        <p className="answer">
          You will be able to mint up to 5 Bogus Fish per transaction in the
          public sale. In the pre-sale you must get permission from the artist
          to mint more than 5 Bogus Fish per transaction.
        </p>
      ),
    },
  ],
};

export default faq;
